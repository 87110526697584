<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">行政审批单处理</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <div class="job_describe_cengter_header">{{ info.QDDX_MC }}</div>

          <div class="user_undergo_box">
            <div class="user_undergo user_undergo_a">
              发送人：{{ info.Staff_Name }}
            </div>
            <div class="user_undergo user_undergo_a">单位：{{ info.Unit }}</div>
            <div class="user_undergo user_undergo_a">
              地点：{{ info.Address }}
            </div>
            <div class="user_undergo user_undergo_a">
              联系人：{{ info.LinkMan }}
            </div>
            <div class="user_undergo user_undergo_a">
              联系电话：{{ info.TEL }}
            </div>
            <div class="user_undergo user_undergo_a">
              所属街道：{{ info.Street }}
            </div>
            <div class="user_undergo user_undergo_a">
              占地面积：{{ info.Area }}
            </div>
            <div class="user_undergo user_undergo_a">类别：{{ info.Type }}</div>
            <div class="user_undergo user_undergo_a">
              开始时间：{{ info.Date_ADT_Start }}
            </div>
            <div class="user_undergo user_undergo_a">
              结束时间：{{ info.Date_ADT_End }}
            </div>
            <div class="user_undergo user_undergo_a">
              审批天数：{{ info.ADT_Term }}
            </div>
            <div class="user_undergo user_undergo_a">
              延期天数：{{ info.Delay_Term }}
            </div>
            <div class="user_undergo user_undergo_a">
              备注：{{ info.Remark }}
            </div>

            <div class="user_undergo user_undergo_a">
              图片信息：
            </div>
            <img
              v-for="(item, i) in info.FileList"
              :key="i"
              :src="item.Url"
              style="width: 80px;height:80px;display: inline-block;padding: 2px"
              @click="previewBefore"
            />
          </div>
        </div>
        <div>
          <van-form>
            <van-field
              v-model="hfNr"
              rows="4"
              autosize
              label="回复内容"
              type="textarea"
              placeholder="请填写回复内容"
            />
            <van-field label="照片上传" />
            <upload-file :limit="9" @fetch-data="getFileList" />
            <div style="margin: 16px;">
              <van-row>
                <van-col :span="12" style="text-align: right;padding: 5px">
                  <van-button round block type="info" @click="Save()">
                    回复
                  </van-button>
                </van-col>
                <van-col :span="12" style="text-align: left;padding: 5px">
                  <van-button round block type="info" @click="ht()">
                    回退
                  </van-button>
                </van-col>
              </van-row>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { systemUrl } from '@/config'
  import UploadFile from '@/components/UploadFile'
  import { Dialog, ImagePreview, Toast } from 'vant'
  import {
    GetAdministrationInfo,
    istAdministrationCell,
    istAdministrationCellFallback,
  } from '@/api/ist/ist/ist'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'IstAdministrationCell',
    components: { UploadFile },
    data() {
      return {
        pkValue: '',
        info: {}, //详情数据
        fileList: [],
        formData: {
          pkValue: '',
          istID: '',
          seqID: '',
          nodeID: '',
          UserID: 0,
          arrs: '',
        },
        hfNr: '',
        filePath: '',
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.pkValue = Base64.decode(this.$route.query.pkValue)
      this.formData.pkValue = this.pkValue
      this.formData.UserID = userInfo.User_ID
      this.formData.istID = Base64.decode(this.$route.query.istID)
      this.formData.seqID = Base64.decode(this.$route.query.seqID)
      this.formData.nodeID = Base64.decode(this.$route.query.nodeID)
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'istAdministrationCell',
        '城管-行政审批网格回复',
        'zzdcg.yy.gov.cn/istAdministrationCell'
      )
    },
    methods: {
      //获取行政审批详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await GetAdministrationInfo({ PK_Value: this.pkValue })
        let fileList = data.File
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            fileList[i].Url = systemUrl + fileList[i].Url
            this.fileList.push(fileList[i].Url)
          }
        }
        data.FileList = fileList
        this.info = data
        console.log(this.info)
        Toast.clear()
      },
      async Save() {
        if (this.hfNr == '') {
          Dialog({ message: '请输入回复内容！' })
          return
        }
        //网格员回复
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        this.filePath = this.fileList.length > 0 ? this.fileList.join('|') : ''
        this.formData.arrs = this.hfNr + '#' + this.filePath
        console.log(this.filePath)
        console.log(this.formData)
        const { success, data, msg } = await istAdministrationCell(
          this.formData
        )
        if (success) {
          Toast.clear()
          Dialog({ message: msg })
          this.onClickRight()
        } else {
          Toast.clear()
          Dialog({ message: msg })
        }
      },
      async ht() {
        if (this.hfNr == '') {
          Dialog({ message: '请输入回复内容！' })
          return
        }
        //行政审批回退
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })

        this.filePath = this.fileList.length > 0 ? this.fileList.join('|') : ''
        this.formData.arrs = this.hfNr + '#' + this.filePath
        console.log(this.formData)
        const { success, data, msg } = await istAdministrationCellFallback(
          this.formData
        )
        if (success) {
          Toast.clear()
          Dialog({ message: msg })
          this.onClickRight()
        } else {
          Toast.clear()
          Dialog({ message: msg })
        }
      },
      previewBefore() {
        ImagePreview({
          images: this.fileList,
        })
      },
      getFileList(val) {
        console.log(val)
        this.fileList = val
      },
      //跳转到流程列表
      onClickRight() {
        this.$router.push({
          path: '/istIndex',
        })
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
</style>
