import request from '@/util/request'

//代办任务列表
export function getIstList(params) {
  return request({
    url: '/IstFlow.asmx/GetIstList',
    method: 'GET',
    params,
  })
}

//行政审批接口
export function GetAdministrationInfo(params) {
  return request({
    url: '/IstFlow.asmx/GetAdministrationInfo',
    method: 'GET',
    params,
  })
}
export function istAdministrationIssued(params) {
  return request({
    url: '/IstFlow.asmx/istAdministrationIssued',
    method: 'GET',
    params,
  })
}

export function istAdministrationFallback(params) {
  return request({
    url: '/IstFlow.asmx/istAdministrationFallback',
    method: 'GET',
    params,
  })
}

export function istAdministrationCell(params) {
  return request({
    url: '/IstFlow.asmx/istAdministrationCell',
    method: 'GET',
    params,
  })
}

export function istAdministrationCellFallback(params) {
  return request({
    url: '/IstFlow.asmx/istAdministrationCellFallback',
    method: 'GET',
    params,
  })
}

//综治办接口
export function officeEventInfo(params) {
  return request({
    url: '/IstFlow.asmx/officeEventInfo',
    method: 'GET',
    params,
  })
}

export function officeEventIssued(params) {
  return request({
    url: '/IstFlow.asmx/officeEventIssued',
    method: 'GET',
    params,
  })
}
export function officeEventCell(params) {
  return request({
    url: '/IstFlow.asmx/officeEventCell',
    method: 'GET',
    params,
  })
}

export function shopColorationCell(params) {
  return request({
    url: '/IstFlow.asmx/ShopColorationCell',
    method: 'GET',
    params,
  })
}

export function shopColorationIssued(params) {
  return request({
    url: '/IstFlow.asmx/ShopColorationIssued',
    method: 'GET',
    params,
  })
}
